/* eslint-disable @typescript-eslint/await-thenable */
import { useForm } from 'react-hook-form';
import { FormFields, FormData } from './types';
import { ApiClient } from '@quiqupltd/quiqupjs';
import { message } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchLogin } from '../../../../redux/modules/user/user.actions';
import { useHistory } from 'react-router-dom';
import { getRootRouteByRole } from '../../../../routing/route';
import { TRACKING_CATEGORIES, trackEvent } from '../../../../globals/analytics';

export const REQUIRED_MESSAGE = 'This field is required';

export const fields: FormFields = {
  fullName: {
    name: 'fullName',
    rules: {
      required: REQUIRED_MESSAGE,
    },
  },
  accountName: {
    name: 'accountName',
    rules: {
      required: REQUIRED_MESSAGE,
    },
  },
  phone: {
    name: 'phone',
    rules: {
      required: REQUIRED_MESSAGE,
      minLength: {
        value: 8,
        message: 'Phone number is too short',
      },
    },
  },
  ordersPerMonth: {
    name: 'ordersPerMonth',
    rules: {
      required: REQUIRED_MESSAGE,
    },
  },
  email: {
    name: 'email',
    rules: {
      required: REQUIRED_MESSAGE,
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        message: 'Please enter a valid email address.',
      },
    },
  },
  password: {
    name: 'password',
    rules: {
      required: REQUIRED_MESSAGE,
      pattern: {
        value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$!%&+,:;=?@#|'<>.^*()%!-]).{8,}$/,
        message: '',
      },
    },
  },
};

interface UserRequestPayload {
  fullName?: string;
  accountName?: string;
  phone?: string;
  ordersPerMonth?: string;
  email?: string;
  password?: string;
  signedTerms?: string;
  utmContent?: string;
  utmMedium?: string;
  utmTerm?: string;
  utmCampaign?: string;
  utmSource?: string;
}

export interface UserInterface {
  id: number;
  fullName: string;
  accountName: string;
  phone: string;
  ordersPerMonth: string;
  email: string;
  password: string;
  signedTerms: string;
}

export function submitUser(data: FormData): Promise<{ data: UserInterface }> {
  const userData: UserRequestPayload = {
    fullName: data[fields.fullName.name],
    accountName: data[fields.accountName.name],
    phone: `+971${data[fields.phone.name]}`,
    ordersPerMonth: data[fields.ordersPerMonth.name],
    email: data[fields.email.name],
    password: data[fields.password.name],
    signedTerms: data.signedTerms,
    utmContent: data.utmContent,
    utmMedium: data.utmMedium,
    utmTerm: data.utmTerm,
    utmCampaign: data.utmCampaign,
    utmSource: data.utmSource,
  };

  return ApiClient.post({
    path: `${window.env.EX_CORE_API_URL}/api/account/register/self`,
    data: userData,
  });
}

type ReturnValidationError = {
  name: string;
  message: string;
  type: string;
};

interface ErrorMap {
  [key: string]: string;
}

function getAsyncValidationErrors(error: string): ReturnValidationError {
  const mapErrorsField = {
    EMAIL_ALREADY_EXISTS: fields.email.name,
    ACCOUNT_NAME_ALREADY_EXISTS: fields.accountName.name,
  };

  const mapErrorsValue: ErrorMap = {
    EMAIL_ALREADY_EXISTS: 'Email already in use',
    ACCOUNT_NAME_ALREADY_EXISTS: 'Account name already in use',
  };

  return {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    name: mapErrorsField[error] ? mapErrorsField[error] : 'password',
    message: mapErrorsValue[error] ? mapErrorsValue[error] : 'Something went wrong. Please try again.',
    type: 'validation',
  };
}
// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export default function useFormState() {
  const [creatingUser, setCreatingUser] = useState<number>(-1);
  const [accountCreated, setAccountCreated] = useState<boolean>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors, control, reset, getValues, formState, setError, setValue } =
    useForm<FormData>({
      mode: 'onBlur',
    });

  const delay = (ms: number): Promise<void> => new Promise((resolve) => setTimeout(resolve, ms));

  const resetForm = (): void => {
    const newValues = {} as FormData;
    Object.keys(getValues()).forEach((field) => {
      newValues[field] = '';
    });

    reset(newValues);
  };
  const onSubmit = async (formData: FormData): Promise<void> => {
    try {
      trackEvent({
        action: 'ssup_signup_page_signup_button_clicked',
        category: TRACKING_CATEGORIES.signup,
      });
      setCreatingUser(0);

      const apiCall = submitUser({
        ...formData,
        accountName: formData.accountName ?? formData.fullName,
        ordersPerMonth: formData.ordersPerMonth ?? 'just starting',
      });
      const progressTo40 = delay(2000).then(() => setCreatingUser(40));

      await progressTo40;
      const { data } = await apiCall;
      trackEvent({
        action: 'Account Created',
        category: TRACKING_CATEGORIES.signup,
      });

      setAccountCreated(true);
      resetForm();

      setCreatingUser(80);

      for (let i = 0; i < 3; i++) {
        try {
          await delay(3000);
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const token: any = await dispatch(fetchLogin({ email: data.email, password: data.password }));

          if (token.roles) {
            setCreatingUser(100);
            history.push(getRootRouteByRole(token.roles));
            break;
          }
        } catch (error) {
          continue;
        }
      }
      setCreatingUser(100);
    } catch (error) {
      if (accountCreated) {
        setCreatingUser(100);
      } else {
        trackEvent({
          action: 'ssup_signup_page_signup_button_clicked_account_creation_error',
          type: 'error',
          label: error?.error ?? error.errorDetail,
          category: TRACKING_CATEGORIES.signup,
        });
        setCreatingUser(-1);
        if (error.status) {
          const apiError = getAsyncValidationErrors(error.error);
          setError(apiError.name, { type: apiError.type, message: apiError.message });
        } else {
          message.error(error.errorDetail);
        }
      }
    }
  };

  return {
    setValue,
    state: formState,
    errors,
    register,
    control,
    handlers: {
      handleSubmit,
      onSubmit,
      handleError: setError,
    },
    values: {
      creatingUser,
    },
  };
}
