export const WAYPOINT_TYPE_PICKUP = 'pickup';
export const WAYPOINT_TYPE_DROPOFF = 'dropoff';
export const WAYPOINT_TYPE_RETURN = 'return';

export const JOB_STATE_ASSIGNED = 'assigned';
export const JOB_STATE_PENDING = 'pending';
export const JOB_STATE_REQUESTED = 'requested';
export const JOB_STATE_RECEIVED = 'received';
export const JOB_STATE_ORDERING = 'ordering';
export const JOB_STATE_PENDING_ASSIGNMENT = 'pending_assignment';
export const JOB_STATE_QUEUED = 'queued';
export const JOB_STATE_PICKUP_STARTED = 'pickup_started';
export const JOB_STATE_PICKUP_ARRIVED = 'pickup_arrived';
export const JOB_STATE_DROP_STARTED = 'drop_started';
export const JOB_STATE_DROP_ARRIVED = 'drop_arrived';
export const JOB_STATE_ITEMS_PURCHASED = 'items_purchased';
export const JOB_STATE_CANCELLED = 'cancelled';
export const JOB_STATE_COMPLETE = 'complete';

export const INITIAL_ZOOM_STATE = 12;

export const PARTNER_SAMEDAY = 'partner_sameday';
export const PARTNER_STANDARD = 'partner_standard';

export const PARTNER_SCHEDULED = 'partner_scheduled';
export const LOCAL_DELIVERY_WINDOW = 'local_delivery_window';

export const CANCELLABLE_JOB_STATES = [
  JOB_STATE_REQUESTED,
  JOB_STATE_PENDING,
  JOB_STATE_RECEIVED,
  JOB_STATE_ORDERING,
  JOB_STATE_PENDING_ASSIGNMENT,
  JOB_STATE_QUEUED,
];

export const PICKUP = 'pickup';
export const DROPOFF = 'dropoff';
export const RETURN = 'return';

export const REGION_CODE_UAE = 'uae';

export const REGION_CODE_DUBAI = 'uae.dubai';
export const REGION_CODE_ABU_DHABI = 'uae.abu_dhabi';

export const TIMEZONE_DUBAI = 'Asia/Dubai';

export const DEFAULT_PHONE_CODE = '+971';

export const DEFAULT_LIVE_ORDERS_PER_PAGE = 50;
export const FETCH_LIVE_JOBS_INTERVAL = 40000;

export const DUBAI_OA_IMAGE = 'https://quiqup-quiqdash-app.s3.amazonaws.com/images/dubai_operating_area.png';
export const SAD_ORDERS_IMAGE = 'https://quiqup-quiqdash-app.s3.amazonaws.com/images/sad-orders.png';

export const WHATSAPP_URL = 'https://api.whatsapp.com/send/?phone=%2B97145126212&text=';
export const MANUAL_URL = 'https://coda.io/@tim-linssen/user-manual';

export const DATE_FORMAT = 'd-M-yyyy';
export const SLASH_DATE_FORMAT = 'dd/MM/yyyy';
export const SUB_DATE_FORMAT = 'dd/MM/yyyy @HH:mm';
export const TIMELINE_DATE_FORMAT = 'dd/MM/yyyy - HH:mm';

export const generateWhatsappMessage = (data, context) => {
  switch (context) {
    case 'addAddress':
      return encodeURIComponent(`Hi! This is ${data}. Please add a new pickup location:`);
    default:
      break;
  }
};

export const TERMS_OF_SERVICE_URL = 'https://www.quiqup.com/terms-of-service/terms-of-service-self-service-24-10-11';
export const INDIVIDUAL_TERMS_OF_SERVICE_URL =
  'https://www.quiqup.com/terms-of-service/individual-quiqup-services-and-fees-schedule---self-service-25-01-21';
export const PRIVACY_POLICY_URL = 'https://www.quiqup.com/privacy-policy/';
export const CONTACT_URL = 'https://www.quiqup.com/contact/';
export const SHOPIFY_APP_URL = 'https://apps.shopify.com/quiqup-connector';
export const WOOCOMMERCE_APP_URL = 'https://wordpress.org/plugins/quiqup-connector';

export const ONBOARDING_CALENDLY_URL = 'https://calendly.com/andrew-mxs0/shopify-migration-call/?from=slack';
export const SALES_CALENDLY_URL = 'https://calendly.com/d/3g5-stq-z3q/onboarding-call';
export const INBOUND_CALENDLY_URL = 'https://calendly.com/d/3g5-stq-z3q/onboarding-call';
